import { useQuery } from '@tanstack/react-query'
import {
	IFiltersLamenessTable,
	InspectionStatus,
	TreatmentStatus,
} from 'components/cattles/lamenessPage/shared/models'
import { TrimStatus } from 'components/cattles/trim/models'
import moment from 'moment'
import { Dispatch, useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'

type TabsDescriptionType = {
	key: string
	title: string
	description: string | null
}

interface ICowsNextPageProps {
	page: number
	setPage?: Dispatch<number>
	activeFilter?: any
	initialPageSize: number
	fatnessPoint?: number[]
	params?: IFiltersLamenessTable
	isFetchCowsListEnabled?: boolean
	isFetchComplete?: boolean
	initialTabs?: TabsDescriptionType[]
}

const useCowsNextPage = (props: ICowsNextPageProps) => {
	const {
		page,
		activeFilter,
		initialPageSize,
		fatnessPoint,
		params,
		isFetchComplete,
		isFetchCowsListEnabled,
		initialTabs,
	} = props

	const [cowsList, setCowsList] = useState([])
	const [totalCountPages, setTotalCountPages] = useState<number>(1)
	const [availableTags, setAvailableTags] = useState([])

	const [availableTabs, setAvailableTabs] = useState(initialTabs)
	const dispatch = useAppDispatch()
	const updateTabsDescription = (
		disposeRecommended,
		examinationOverdue,
		examinationRequired,
		trimMissed
	) => {
		setAvailableTabs(tabs =>
			tabs?.map(tab => {
				switch (tab.key) {
					case InspectionStatus.Required:
						return {
							...tab,
							description:
								examinationRequired > 0 ? examinationRequired : tab.description,
						}
					case InspectionStatus.Expired:
						return {
							...tab,
							description:
								examinationOverdue > 0 ? examinationOverdue : tab.description,
						}
					case TreatmentStatus.DisposeRecommended:
						return {
							...tab,
							description:
								disposeRecommended > 0 ? disposeRecommended : tab.description,
						}
					case TrimStatus.Missed:
						return {
							...tab,
							description: trimMissed > 0 ? trimMissed : tab.description,
						}
					default:
						return tab
				}
			})
		)
	}
	const {
		data,
		isLoading: fetchingCowsList,
		isSuccess,
		isError,
	} = useQuery({
		queryKey: ['cowsList', page, activeFilter, fatnessPoint, params],
		queryFn: () =>
			CattlesApiService.getCowsList(
				activeFilter === 'all' || !activeFilter ? null : activeFilter,
				fatnessPoint,
				page,
				initialPageSize,
				params
			),
		enabled: isFetchCowsListEnabled && isFetchComplete,
		staleTime: 50000,
	})

	useEffect(() => {
		if (isSuccess) {
			const {
				count,
				results,
				available_tags,
				dispose_recommended,
				examination_overdue,
				examination_required,
				trim_missed,
			} = data.data

			setTotalCountPages(count)
			setCowsList(results)
			const tagsWithTitleAndIndex = available_tags
				.filter(el => el !== null)
				.map(el => ({
					title: el.toString(),
					id: el,
				}))
			setAvailableTags(tagsWithTitleAndIndex)
			updateTabsDescription(
				dispose_recommended,
				examination_overdue,
				examination_required,
				trim_missed
			)

			const isMissedTrim = results.some(cow =>
				moment().isAfter(cow.trim_next_dt)
			)
			if (isMissedTrim) {
				dispatch(
					notifyUser({
						title: 'Пропущена обрезка копыт',
						description: 'Не проведена запланированная обрезка копыт.',
						isError: true,
					})
				)
			}
		}
	}, [data, dispatch, isSuccess])

	useEffect(() => {
		if (isError) {
			dispatch(
				notifyUser({
					title: 'Ошибка при формировании списка коров',
					description: 'Попробуйте обновить страницу',
					isError: true,
				})
			)
		}
	}, [dispatch, isError])

	return {
		cowsList,
		totalCountPages,
		fetchingCowsList,
		availableTags,
		availableTabs,
	}
}

export default useCowsNextPage
