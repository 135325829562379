import { Box } from '@mui/material'
import { Milkings } from 'models/IFarm'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { Row } from 'ui/Row'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface WidgetProps {
	visible: boolean
	milking: Milkings
	isLoading: boolean
}

export const MilkingWidget: React.FC<WidgetProps> = ({
	visible,
	milking,
	isLoading,
}) => {
	if (!visible) return null
	if (isLoading || !milking)
		return <SkeletonEmptyCard height={'280px'} needTitle heightTitle={'24px'} />
	const totalMilk = milking?.to_sell + milking?.rejected_milk
	const yesterdayString = `${moment(milking.timestamp)
		.format('dddd, DD.MM.YYYY')
		.charAt(0)
		.toUpperCase()}${moment(milking.timestamp)
		.format('dddd, DD.MM.YYYY')
		.slice(1)}`
	return (
		<CardStandart sx={{ padding: '24px', height: '360px', width: '100%' }}>
			<Row $justify='space-between'>
				<TypographyHeader>Надои</TypographyHeader>
				{/* <CustomTooltip
					placement={'bottom'}
					width={'14px'}
					height={'14px'}
					info={false}
					title={
						'Стоимость реализации 1 л товарного молока рассчитана по цене контрагента 32 руб/л «ООО ЗМК».'
					}
				/> */}
			</Row>
			<TypographySecondary14 mt={'8px'}>
				{yesterdayString}
			</TypographySecondary14>
			<Box display='flex' flexDirection='column' gap='35px' mt='24px'>
				{/* 1 row */}
				<Box display='flex' flexDirection='column' gap='4px'>
					<Row $justify='space-between'>
						<TypographySecondary14>Всего</TypographySecondary14>
						<TypographySecondary>В среднем на голову</TypographySecondary>
					</Row>
					<Row $justify='space-between'>
						<TypographyPrimary16Bold>
							{milking.today_total}
							<span
								style={{
									fontWeight: 400,
									fontSize: '12px',
									color: COLORS.secondary,
								}}
							>
								{' л'}
							</span>
						</TypographyPrimary16Bold>
						<TypographyPrimary>
							{milking.today_total_avg}{' '}
							<span
								style={{
									fontWeight: 400,
									fontSize: '12px',
									color: COLORS.secondary,
								}}
							>
								{' л'}
							</span>
						</TypographyPrimary>
					</Row>
				</Box>

				{/* 2 row */}
				<Box display='flex' flexDirection='column' gap='4px'>
					<Row $justify='space-between'>
						<TypographySecondary>Товарное молоко</TypographySecondary>
						<TypographySecondary>В среднем на голову</TypographySecondary>
					</Row>
					<Row $justify='space-between'>
						<Row $gap='4px'>
							<TypographyPrimary16Bold>
								{milking.to_sell}
							</TypographyPrimary16Bold>
							<TypographySecondary>{'л'}</TypographySecondary>

							{!isNaN((milking.to_sell / totalMilk) * 100) && (
								<>
									<TriangleSpan
										width={20}
										height={10}
										color={milking.to_sell_more ? COLORS.success : COLORS.error}
										top={Boolean(milking.to_sell_more)}
									/>
									<TypographySecondary>{'/'}</TypographySecondary>
									<TypographyPrimary>
										{(milking.to_sell / totalMilk) * 100}
									</TypographyPrimary>
									<TypographySecondary>{'%'}</TypographySecondary>
								</>
							)}
						</Row>
						<Row $gap='4px' $justify='flex-end'>
							<TypographyPrimary>{milking.to_sell_avg}</TypographyPrimary>
							<TypographySecondary>{'л'}</TypographySecondary>
							{/* <TypographySecondary>{'/'}</TypographySecondary>
							<TypographyPrimary>{milking.to_sell_avg_cost}</TypographyPrimary>
							<TypographySecondary>{'руб'}</TypographySecondary> */}
						</Row>
					</Row>
				</Box>

				{/* 3 row */}
				<Box display='flex' flexDirection='column' gap='4px'>
					<Row $justify='space-between'>
						<TypographySecondary>Бракованное молоко</TypographySecondary>
						<TypographySecondary>В среднем на голову</TypographySecondary>
					</Row>
					<Row $justify='space-between'>
						<Row $gap='4px'>
							<TypographyPrimary16Bold>
								{milking.rejected_milk}
							</TypographyPrimary16Bold>
							<TypographySecondary>{'л'}</TypographySecondary>
							{!isNaN((milking.rejected_milk / totalMilk) * 100) && (
								<>
									<TriangleSpan
										width={20}
										height={10}
										color={
											Boolean(milking.rejected_milk_more)
												? COLORS.success
												: COLORS.error
										}
										top={!Boolean(milking.rejected_milk_more)}
									/>
									<TypographySecondary>{'/'}</TypographySecondary>
									<TypographyPrimary>
										{(milking.rejected_milk / totalMilk) * 100}
									</TypographyPrimary>
									<TypographySecondary>{'%'}</TypographySecondary>
								</>
							)}
						</Row>
						<Row $gap='4px' $justify='flex-end'>
							<TypographyPrimary>{milking.rejected_milk_avg}</TypographyPrimary>
							<TypographySecondary>{'л'}</TypographySecondary>
							{/* <TypographySecondary>{'/'}</TypographySecondary>
							<TypographyPrimary>{milking.rejected_milk_avg_cost}</TypographyPrimary>
							<TypographySecondary>{'руб'}</TypographySecondary> */}
						</Row>
					</Row>
				</Box>
			</Box>
		</CardStandart>
	)
}

const TriangleSpan = props => {
	const { width, height, color, top } = props
	return (
		<span
			style={{
				width: '0px',
				height: '0px',
				borderLeft: `${width / 2}px solid transparent`,
				borderRight: `${width / 2}px solid transparent`,
				borderTop: !top ? `${height}px solid ${color}` : 'none',
				borderBottom: top ? `${height}px solid ${color}` : 'none',
				display: 'inline-block',
			}}
		></span>
	)
}
