import { styled } from '@mui/material';
import { IFarmStatistics } from 'models/IFarm';
import {
	AveragePSVWidget,
	FoodDistributeWidget,
	FoodRemainedWidget,
} from './widgets';

interface WidgetProps {
	visible: boolean;
	className?: string;
	isLoading: boolean;
	statistics: IFarmStatistics;
}
const FoodTableContainer = styled('div')`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	gap: 24px;
	width: 100%;
`;
export const FoodTableBlock: React.FC<WidgetProps> = ({
	visible,
	className,
	isLoading,
	statistics,
}) => {
	if (!visible) return null;
	const totalFood = statistics?.cleaning.total + statistics?.distribution.total;
	return (
		<FoodTableContainer className={className}>
			<FoodDistributeWidget
				visible={true}
				distribute={statistics?.distribution}
				totalFood={totalFood}
				isLoading={isLoading}
			/>
			<FoodRemainedWidget
				visible={true}
				cleaning={statistics?.cleaning}
				totalFood={totalFood}
				isLoading={isLoading}
			/>
			<AveragePSVWidget
				visible={true}
				psv={statistics?.psv_avg}
				isLoading={isLoading}
			/>
		</FoodTableContainer>
	);
};
