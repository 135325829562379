import { Box } from '@mui/material'
import { PsvAvg } from 'models/IFarm'
import moment from 'moment'
import { CardStandart } from 'ui/CardStandart'
import { Row } from 'ui/Row'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface WidgetProps {
	visible: boolean
	psv: PsvAvg
	isLoading: boolean
}

export const AveragePSVWidget: React.FC<WidgetProps> = ({
	visible,
	isLoading,
	psv,
}) => {
	if (!visible) return null
	if (isLoading || !psv) return <SkeletonEmptyCard height={'50px'} />
	return (
		<CardStandart sx={{ padding: '24px', height: '104px' }}>
			<Box display='flex' flexDirection='column' gap='20px'>
				{/* 1 row */}

				<Row $justify='space-between'>
					{psv?.total ? (
						<Row $gap='4px'>
							<TypographyPrimary16Bold>
								{psv?.total?.toLocaleString()}
							</TypographyPrimary16Bold>
							<TypographySecondary>{'кг/голову'}</TypographySecondary>
						</Row>
					) : (
						<TypographySecondary14>Нет данных</TypographySecondary14>
					)}
					{/* <Row $justify='flex-end'>
							<TypographyPrimary>
								{roundingNumber(distribute.cost, 1)}
							</TypographyPrimary>
							<TypographySecondary>руб</TypographySecondary>
						</Row> */}
				</Row>
				{/* 2 row */}
				<Row $justify='space-between'>
					<TypographyPrimary>{'Среднее ПСВ'}</TypographyPrimary>
					<TypographySecondary>
						{`${moment(psv?.timestamp)
							.format('dddd, DD.MM.YYYY')
							.charAt(0)
							.toUpperCase()}${moment(psv?.timestamp)
							.format('dddd, DD.MM.YYYY')
							.slice(1)}`}
					</TypographySecondary>
				</Row>
			</Box>
		</CardStandart>
	)
}
