import { Box } from '@mui/material'
import { ReactElement } from 'react'
import { TypographyPrimary } from './typography/TypographyPrimary'

interface IInfoChipItemProps {
	bgColor: string
	label: string
	labelColor: string
	icon?: ReactElement
}

const InfoChipItem = (props: IInfoChipItemProps) => {
	const { bgColor, label, labelColor, icon } = props
	return (
		<Box
			sx={{
				width: 'auto',
				borderRadius: '12px',
				height: 'auto',
				backgroundColor: bgColor,
				padding: '4px 8px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '4px',
			}}
		>
			{icon && icon}
			<TypographyPrimary
				sx={{
					textAlign: 'center',
					color: labelColor,
				}}
			>
				{label}
			</TypographyPrimary>
		</Box>
	)
}

export default InfoChipItem
