import Box from '@mui/material/Box/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import moment from 'moment'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16'
import { COLORS } from '../../styles/colors'
import { localizationApexCharts } from '../../utils'
import { tooltipForHeadChart } from './TooltipsForCharts/tooltipsForCharts'
import './styles/module.ApexChartTooltip.css'

const GroupApexHeadQty = props => {
	const [cursorPosition, setCursorPosition] = useState(null)

	const dataYasis = props.fetchData.map(el => Math.round(el.quantity))
	const dataXasis = props.fetchData.map(el => el.changed_dt)
	const maxSeriesValue = Math.max(...dataYasis)

	const yearsValues = []

	const handleMouseMove = e => {
		if (props.daysBetweenDates === 1) {
			const chartElement = document.getElementById('headChart')
			if (chartElement) {
				const chartRect = chartElement.getBoundingClientRect()
				const mouseX = e.clientX - chartRect.left
				const chartWidth = chartRect.width

				if (mouseX <= chartWidth / 2) {
					setCursorPosition('left')
				} else {
					setCursorPosition('right')
				}
			}
		}
	}

	for (let i = 0; i < 12; i++) {
		const startDate = moment().subtract(i, 'months').startOf('month')
		const endDate = moment().subtract(i, 'months').endOf('month')
		const filteredData = props.fetchData.filter(data =>
			moment(data.changed_dt).isBetween(startDate, endDate, null, '[]')
		)
		const total = filteredData.reduce((sum, data) => sum + data.quantity, 0)
		const averageCount =
			filteredData.length > 0 ? Math.round(total / filteredData.length) : 0
		const dateInMilliseconds = startDate.valueOf()
		const monthData = { changed_dt: dateInMilliseconds, quantity: averageCount }
		yearsValues.unshift(monthData)
	}
	const dataYYearsValues = yearsValues?.map(el => el.quantity)
	const dataXYearsValues = yearsValues?.map(el => el.changed_dt)
	const maxYearsSeriesValue = Math.max(...dataYYearsValues)

	const series: any = [
		{
			name: 'Количество голов',
			data:
				props.daysBetweenDates === 366 || props.daysBetweenDates === 365
					? dataYYearsValues
					: dataYasis,
		},
	]
	const options: any = {
		mask: false,
		colors: [COLORS.main],
		chart: {
			zoom: {
				enabled: false,
			},
			stacked: true,
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			height: 250,
			type: 'bar',
			toolbar: {
				show: false,
			},
		},
		title: {
			style: {
				fontSize: '12px',
				fontFamily: 'Montserrat',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '15px',
				color: '#7F7F84',
			},
			text: 'Количество голов',
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			padding: {
				left: 0,
				right: 10,
			},
			strokeDashArray: [6, 3],
			show: true,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
		},

		plotOptions: {
			bar: {
				horizontal: false,
				borderRadius:
					props.daysBetweenDates <= 1
						? 20
						: props.daysBetweenDates <= 60
						? 8
						: props.daysBetweenDates <= 91
						? 4
						: 8,
				borderRadiusApplication: 'end',
				columnWidth:
					props.daysBetweenDates <= 1
						? '5%'
						: props.daysBetweenDates <= 2
						? '10%'
						: props.daysBetweenDates <= 4
						? '15%'
						: props.daysBetweenDates <= 10
						? '30%'
						: props.daysBetweenDates <= 20
						? '40%'
						: '60%',
				dataLabels: {
					position: 'center',
					hideOverflowingLabels: true,
					total: {
						offsetX: 200,
						enabled: false,
						style: {
							fontSize: '13px',
							fontWeight: 900,
						},
					},
				},
			},
		},
		fill: {
			opacity: 1,
		},
		xaxis: {
			crosshairs: {
				show: false,
			},
			type: 'category',
			tickPlacement: 'on',
			tickAmount: 31,
			tooltip: {
				enabled: false,
			},
			labels: {
				rotate: 0,
				trim: false,
				hideOverlappingLabels: false,
				formatter: function (value) {
					const momentValue = moment(value)

					if (momentValue.date() === 1 && props.daysBetweenDates <= 92) {
						return momentValue.format('DD.MM')
					} else {
						return props.daysBetweenDates <= 7
							? momentValue.format('DD.MM.YY')
							: props.daysBetweenDates <= 92
							? momentValue.format('D')
							: momentValue.format('MMMM')
					}
				},

				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
			},
			categories:
				props.daysBetweenDates === 366 || props.daysBetweenDates === 365
					? dataXYearsValues
					: dataXasis,
		},
		yaxis: {
			crosshairs: {
				show: false,
			},
			tickAmount: 4,
			max:
				props.daysBetweenDates === 366 || props.daysBetweenDates === 365
					? maxYearsSeriesValue
					: maxSeriesValue,
			min: 0,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number, index: number) {
					if (index % 2 !== 0) {
						return ''
					}
					return Math.round(value).toLocaleString().toString()
				},
			},
		},
		states: {
			normal: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			hover: {
				filter: {
					type: 'none',
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
					value: 0,
				},
			},
		},
		tooltip: {
			enable: true,
			intersect: true,
			shared: false,
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				return tooltipForHeadChart(
					series,
					dataPointIndex,
					props.daysBetweenDates,
					dataXasis,
					dataXYearsValues
				)
			},
		},
		legend: {
			show: false,
		},
	}

	return (
		<Box id={'#chart'}>
			{props.loading ? (
				<Box
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': {
								color: COLORS.secondaryFontOpacity,
							},
						}}
					/>
				</Box>
			) : props.fetchData.length === 0 ? (
				<>
					<TypographySecondaryBold16 sx={{ textAlign: 'center' }}>
						Нет данных
					</TypographySecondaryBold16>
					<TypographySecondary14
						sx={{ marginTop: '12px', textAlign: 'center' }}
					>
						Нет данных, соответствующих заданным условиям.
					</TypographySecondary14>
				</>
			) : (
				<Box>
					<Box
						style={{ marginLeft: '-8px' }}
						id={'headQty'}
						onMouseMove={handleMouseMove}
					>
						<style>
							{`
      #headQty .apexcharts-bar-series > g > path:first-child, 
			#headQty .apexcharts-xaxis-texts-g > text:first-child, 
			#headQty .apexcharts-gridlines-vertical > line:first-child {
        ${props.daysBetweenDates === 1 ? 'transform: translateX(30%);' : ''}
      }
      #headQty .apexcharts-bar-series > g > path,
			#headQty .apexcharts-xaxis-texts-g > text, 
			#headQty .apexcharts-gridlines-vertical > line {
        ${props.daysBetweenDates === 1 ? 'transform: translateX(-30%);' : ''}
      }
			#headQty .apexcharts-inner > line:nth-child(2) {
				${
					props.daysBetweenDates === 1
						? 'transform: translateX(30%) translateY(-176px);'
						: ''
				}
			 }
			 #headQty .apexcharts-inner > line:nth-child(3) {
				${
					props.daysBetweenDates === 1
						? 'transform: translateX(-30%) translateY(-176px);'
						: ''
				}
			 }
			 ${
					props.daysBetweenDates === 1 &&
					`#headQty .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {left: ${
						cursorPosition === 'left' ? '28%' : '60%'
					} !important;}`
				}
    `}
						</style>
						<ReactApexChart
							id={'apex-chart'}
							options={options}
							series={series}
							type='bar'
							height={250}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default GroupApexHeadQty
