import Box from '@mui/material/Box/Box';
import { CardStandart } from 'ui/CardStandart';
import { COLORS } from '../../../styles/colors';
import { TypographyHeader } from '../../../ui/typography/TypographyHeader';
import { TypographySecondary } from '../../../ui/typography/TypographySecondary';
import CustomTooltip from '../../shared/CustomTooltip';
import FoodTableChart from '../shared/FoodtableChart';

const FoodTableInfo = () => {
	const labelForLegend = (color: string, text: string, key: number) => {
		return (
			<Box
				key={key}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<span
					style={{
						background: color,
						width: '16px',
						height: '16px',
						borderRadius: '50%',
					}}
				></span>
				<TypographySecondary>{text}</TypographySecondary>
			</Box>
		);
	};
	const listForLegend = [
		{
			color: COLORS.success,
			text: 'Корм доступен',
		},
		{
			color: COLORS.health,
			text: 'Корм недоступен',
		},
		{
			color: COLORS.error,
			text: 'Кормовой стол пустой',
		},
		{
			color: COLORS.secondaryFont,
			text: 'Нет данных',
		},
	];
	return (
		<CardStandart sx={{ padding: '24px', margin: '24px 0px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<TypographyHeader>Доступность корма</TypographyHeader>
				<CustomTooltip
					placement={'left'}
					width={'14px'}
					height={'14px'}
					info={false}
					title={
						'График отображает объем корма в зоне доступности и в зоне недоступности на текущий момент времени для каждой группы.'
					}
				/>
			</Box>
			<FoodTableChart />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '24px',
				}}
			>
				{listForLegend.map((el, index) =>
					labelForLegend(el.color, el.text, index)
				)}
			</Box>
		</CardStandart>
	);
};

export default FoodTableInfo;
