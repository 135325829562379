import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axAPI from '../http'

import { BarnType, IBarnState } from '../models'

export const fetchBarnList = createAsyncThunk(
	'barn/list',
	async function (_, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'barn/list',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive groups!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

const initialState: IBarnState = {
	barnList: undefined,
	loading: false,
	error: '',
}

export const barnSlice = createSlice({
	name: 'barn',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchBarnList.fulfilled.type]: (
			state,
			action: PayloadAction<BarnType[]>
		) => {
			state.loading = false
			state.error = ''
			state.barnList.push(...action.payload)
		},
		[fetchBarnList.pending.type]: state => {
			state.loading = true
			state.barnList = []
		},
		[fetchBarnList.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
		},
	},
})

export default barnSlice.reducer
