import { Box } from '@mui/material'
import { roundingNumber } from 'components/shared/processes'
import { Cleaning } from 'models/IFarm'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { Row } from 'ui/Row'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface WidgetProps {
	visible: boolean
	cleaning: Cleaning
	totalFood: number
	isLoading: boolean
}

export const FoodRemainedWidget: React.FC<WidgetProps> = ({
	visible,
	cleaning,
	totalFood,
	isLoading,
}) => {
	if (!visible) return null
	if (isLoading) return <SkeletonEmptyCard height={'50px'} />
	const percent = totalFood ? (cleaning.total / totalFood) * 100 : 0
	return (
		<CardStandart sx={{ padding: '24px', height: '104px' }}>
			<Box display='flex' flexDirection='column' gap='20px'>
				{/* 1 row */}

				<Row $justify='space-between'>
					{!cleaning || !totalFood ? (
						<TypographySecondary14>Нет данных</TypographySecondary14>
					) : (
						<Row $gap='4px'>
							<TypographyPrimary16Bold
								sx={{ color: percent > 5 ? COLORS.error : COLORS.black }}
							>
								{cleaning.total.toLocaleString()}
							</TypographyPrimary16Bold>
							<TypographySecondary>{'кг / '}</TypographySecondary>
							<TypographyPrimary
								sx={{ color: percent > 5 ? COLORS.error : COLORS.black }}
							>
								{roundingNumber(percent, 1)}
							</TypographyPrimary>
							<TypographySecondary>{'%'}</TypographySecondary>
						</Row>
					)}
					{/* <Row $justify='flex-end'>
							<TypographyPrimary>
								{roundingNumber(cleaning.cost, 1)}
							</TypographyPrimary>
							<TypographySecondary>руб</TypographySecondary>
						</Row> */}
				</Row>
				{/* 2 row */}
				<Row $justify='space-between'>
					<TypographyPrimary>{'Остаток корма'}</TypographyPrimary>
					<TypographySecondary>
						{`${moment(cleaning?.timestamp)
							.format('dddd, DD.MM.YYYY')
							.charAt(0)
							.toUpperCase()}${moment(cleaning?.timestamp)
							.format('dddd, DD.MM.YYYY')
							.slice(1)}`}{' '}
					</TypographySecondary>
				</Row>
			</Box>
		</CardStandart>
	)
}
