import { Box } from '@mui/material'
import DonutChart from 'components/cattles/cattlesPage/shared/DonutChart'
import NoDataBlock from 'components/foodTable/shared/NoDataBlock'
import { morphForWords } from 'components/shared/processes'
import { Limpness } from 'models/IFarm'
import { CardStandart } from 'ui/CardStandart'
import { Row } from 'ui/Row'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface WidgetProps {
	visible: boolean
	lameness: Limpness[]
	isLoading: boolean
}

export const LamenessWidget: React.FC<WidgetProps> = ({
	visible,
	lameness,
	isLoading,
}) => {
	if (!visible) return null
	if (isLoading || !lameness)
		return <SkeletonEmptyCard height={'280px'} needTitle heightTitle={'24px'} />
	const isValidData = lameness.reduce((el, sum) => el + sum.value, 0)
	const donutOptions = lameness.map(el => el.value)
	const colors = lameness.map(el => el.color)
	return (
		<CardStandart sx={{ padding: '24px', height: '360px', width: '100%' }}>
			<TypographyHeader>Хромота</TypographyHeader>
			{!isValidData ? (
				<NoDataBlock />
			) : (
				<Box
					sx={{
						display: 'flex',
						height: '100%',
						flexDirection: 'row',
						alignItems: 'center',
						width: '100%',
						gap: '80px',
						paddingLeft: '20px',
						paddingBottom: '30px',
					}}
				>
					<DonutChart
						categories={donutOptions}
						colors={colors}
						width={'200px'}
						height={'200px'}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '17px',
						}}
					>
						{lameness.map(el => (
							<Row key={el.label} $justify='space-between' $gap='10px'>
								<Row $gap='4px'>
									<span
										style={{
											width: '8px',
											height: '8px',
											backgroundColor: el.color,
											borderRadius: '50%',
										}}
									></span>
									<TypographySecondary14>
										{el.label.slice(0, 3)}{' '}
									</TypographySecondary14>
								</Row>
								<Row $gap='4px'>
									<TypographyPrimary>{el.value}</TypographyPrimary>
									<TypographySecondary>
										{morphForWords(el.value, ['голов', 'головы', 'голов'])}
									</TypographySecondary>
								</Row>
							</Row>
						))}
					</Box>
				</Box>
			)}
		</CardStandart>
	)
}
