import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'

import DeleteButton from '../../ui/buttons/DeleteButton'
import './styles/notif.css'

import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axAPI from '../../http'
import { COLORS } from '../../styles/colors'
import NotificationItem from './NotificationItem'
const NotificationsPrev = ({
	prevNotif,
	remove,
	setPrevNotifications,
	setCountNotif,
	fetching,
}) => {
	const removeAllPrevNotifications = () => {
		setPrevNotifications([])
		axAPI({
			method: 'PATCH',
			url: `notifications/bulk`,
			data: { id: [], close_today: false, close_old: true },
		}).then(response => {
			if (response.status === 201)
				axAPI({
					method: 'GET',
					url: 'notifications/count',
				}).then(response => {
					setCountNotif(response.data.new_count)
				})
		})
	}
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '8px',
				}}
			>
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '16.8px',
						fontFamily: 'Montserrat',
						color: '#fff',
					}}
				>
					Прошедшие
				</Typography>
				<Box onClick={removeAllPrevNotifications}>
					<DeleteButton children={'Очистить'} />
				</Box>
			</Box>
			<TransitionGroup>
				{prevNotif.map(el => (
					<CSSTransition key={el.id} timeout={500} classNames={'notif'}>
						<NotificationItem
							remove={remove}
							id={el.id}
							title={el.title}
							text={el.text}
							groups={el.groups}
							created_dt={el.created_dt}
						/>
					</CSSTransition>
				))}
			</TransitionGroup>
			{fetching ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</Box>
			) : (
				<></>
			)}
		</Box>
	)
}

export default NotificationsPrev
