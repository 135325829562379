import { Box } from '@mui/material'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

const NoDataBlock = () => {
	return (
		<Box
			sx={{
				marginTop: '30%',
				textAlign: 'center',
			}}
		>
			<TypographySecondary14>Нет данных</TypographySecondary14>
		</Box>
	)
}
export default NoDataBlock
