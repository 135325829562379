import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactComponent as HealthLogo } from 'assets/icons/health-logo.svg';
import { ReactComponent as LactationLogo } from 'assets/lactation-logo.svg';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InfoChipItem from 'ui/InfoChipItem';
import { ReactComponent as ArrowBackIosIcon } from '../assets/arrow_back_logo.svg';
import { ReactComponent as TagNumberIcon } from '../assets/icons/tag-icon.svg';
import { fetchGroupList } from '../store/groupsSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { COLORS } from '../styles/colors';
import MontserratTtf from '../styles/fonts/Montserrat-Medium.ttf';
import { TypographyHeaderExtra } from '../ui/typography/TypographyHeaderExtra';
import { TypographySecondary } from '../ui/typography/TypographySecondary';
import { drawerWidth } from '../variables';
import {
	InspectionStatus,
	TreatmentStatus,
} from './cattles/lamenessPage/shared/models';
import ActiveComp from './rationSystem/utils/ActiveComp';
import { getHeaderTitle } from './shared/processes';

const theme = createTheme({
	palette: {
		background: {
			default: '#F7F7F7',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1920,
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				h4: {
					fontSize: '28px',
					fontWeight: 'bold',
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				gutters: {
					backgroundColor: '#fff',
					color: '#000',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Montserrat'), local('Montserrat-Medium'), url(${MontserratTtf}) format('ttf');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					padding: '0.75rem',
					boxShadow: 'none',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Montserrat, sans-serif',
	},
});

const yieldStatusColor = {
	rejected: COLORS.error,
	dry: COLORS.health,
};

const Header = () => {
	const { groupList } = useAppSelector(store => store.groupsReducer);
	const { rationInfo } = useAppSelector(state => state.rationSystemReducer);
	const { cattleStatuses } = useAppSelector(state => state.cattlesSlice);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const urlParams = useParams();
	const previousLocationRef = useRef<string | null>(null);

	useEffect(() => {
		if (groupList.length === 0) {
			dispatch(fetchGroupList());
		}
	}, [dispatch, groupList.length]);

	useEffect(() => {
		previousLocationRef.current = location.pathname;
	}, [location]);

	const currentGroupTitle = groupList?.find(
		el =>
			el.id.toString() === urlParams?.groupkey || el.key === urlParams?.groupkey
	)?.title;

	const header_title = getHeaderTitle(
		location.pathname,
		urlParams,
		rationInfo,
		currentGroupTitle
	);

	const today = new Date()
		.toISOString()
		.slice(0, 10)
		.split('-')
		.reverse()
		.join('.');

	const hour = new Date().getHours();
	const minute = new Date().getMinutes();

	const handleBackClick = fallbackPath => {
		if (previousLocationRef.current) {
			navigate(-1);
		} else {
			navigate(fallbackPath);
		}
	};

	const isErrorCowStatus =
		cattleStatuses.treatmentStatus === InspectionStatus.Required ||
		cattleStatuses.treatmentStatus === TreatmentStatus.DisposeRecommended ||
		cattleStatuses.treatmentStatus === TreatmentStatus.Suspended ||
		cattleStatuses.fatnessStatus === 'attention';

	const isWarningStatus =
		cattleStatuses.treatmentStatus === TreatmentStatus.Treated ||
		cattleStatuses.fatnessStatus === 'expired';

	return (
		<ThemeProvider theme={theme}>
			<AppBar
				position='absolute'
				color='transparent'
				sx={{
					width: `calc(100% - ${drawerWidth}px)`,
					ml: `${drawerWidth}px`,
					height: '64px',
				}}
			>
				<Container
					maxWidth={false}
					sx={{ ml: '0px', mr: '0px', pr: '0px !important', width: '100%' }}
				>
					<Toolbar
						sx={{
							backgroundColor: theme.palette.background.default,
							paddingLeft: '0px !important',
						}}
					>
						<Box
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '2px',
							}}
						>
							{location.pathname === `/feed/groups/${urlParams.groupkey}` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed/groups')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}

							{location.pathname ===
								`/feed/groups/${urlParams.groupkey}/edit` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() =>
										handleBackClick(`/feed/groups/${urlParams.groupkey}`)
									}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{(location.pathname === `/feed/ration/${urlParams.rationid}` ||
								location.pathname === '/feed/ration/create') && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed/ration')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname ===
								`/feed/ration/${urlParams.rationid}/edit` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() =>
										handleBackClick(`/feed/ration/${urlParams.rationid}`)
									}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === `/cattles/cow/${urlParams.tagNumber}` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === `/feed/deviations` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}

							{location.pathname === '/settings/management' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/settings')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === '/settings/management/create-user' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/settings/management')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname ===
								`/settings/management/${urlParams.userId}/edit` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/settings/management')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{(location.pathname === `/feed/plan/history` ||
								location.pathname ===
									`/feed/plan/history/${urlParams.groupkey}`) && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed/plan')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === `/feed/plan/edit` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed/plan')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === `/feed/analytics/feeding` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed/analytics')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}

							{location.pathname ===
								`/cattles/digitization/${urlParams.cameraId}` && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === '/cattles/lameness' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === '/cattles/fatness' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}

							{location.pathname === '/cattles/trim' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles/lameness')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === '/cattles/trim/edit' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles/trim')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}
							{location.pathname === '/cattles/trim/check-up' && (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/cattles/trim')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							)}

							{header_title === 'Создание плана кормления' ? (
								<Box
									sx={{ mt: '6px', cursor: 'pointer' }}
									onClick={() => handleBackClick('/feed/plan')}
								>
									<ArrowBackIosIcon
										style={{ marginLeft: '-10px', color: COLORS.icon }}
									/>
								</Box>
							) : (
								''
							)}

							<TypographyHeaderExtra id={'headerTitle'}>
								{header_title}
							</TypographyHeaderExtra>
							{header_title === 'Ферма' && (
								<Box
									sx={{
										position: 'absolute',
										top: '36px',
										cursor: 'pointer',
										left: '4px',
										flexWrap: 'nowrap',
									}}
								>
									<TypographySecondary sx={{ width: '200px' }}>
										Сегодня, {today} {hour < 10 ? `0${hour}` : hour}:
										{minute < 10 ? `0${minute}` : minute}
									</TypographySecondary>
								</Box>
							)}
							{location.pathname === `/feed/ration/${urlParams.rationid}` && (
								<Box sx={{ marginLeft: '8px' }}>
									<ActiveComp
										is_archived={rationInfo?.is_archived}
									></ActiveComp>
								</Box>
							)}
							{location.pathname === `/cattles/cow/${urlParams.tagNumber}` && (
								<Box
									sx={{
										marginLeft: '12px',
										display: ' flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '4px',
									}}
								>
									<TagNumberIcon width={'30px'} height={'30px'} />
									<TypographyHeaderExtra>
										{urlParams.tagNumber}
									</TypographyHeaderExtra>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: '10px',
											marginLeft: '18px',
										}}
									>
										{cattleStatuses.yieldStatus !== 'ok' && (
											<LactationLogo
												fill={yieldStatusColor[cattleStatuses.yieldStatus]}
											/>
										)}
										{(isErrorCowStatus || isWarningStatus) && (
											<HealthLogo
												fill={isErrorCowStatus ? COLORS.error : COLORS.health}
												stroke={isErrorCowStatus ? COLORS.error : COLORS.health}
											/>
										)}
										{cattleStatuses.treatmentStatus ===
											TreatmentStatus.DisposeRecommended && (
											<InfoChipItem
												bgColor={COLORS.error10}
												label={'Рекомендована на выбытие'}
												labelColor={COLORS.error}
											/>
										)}
									</Box>
								</Box>
							)}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	);
};

export default Header;
