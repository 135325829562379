import { Box } from '@mui/material';
import ArrowCupidon from 'assets/arrow-full';
import CustomTooltip from 'components/shared/CustomTooltip';
import { morphForWords } from 'components/shared/processes';
import { DisposeRecommended } from 'models/IFarm';
import moment from 'moment';
import { COLORS } from 'styles/colors';
import { CardStandart } from 'ui/CardStandart';
import { Row } from 'ui/Row';
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard';
import { TypographyPrimary } from 'ui/typography/TypographyPrimary';
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold';
import { TypographySecondary } from 'ui/typography/TypographySecondary';

interface WidgetProps {
	visible: boolean;
	disposedQty: DisposeRecommended;
	isLoading: boolean;
}

export const DisposeRecommendedWidget: React.FC<WidgetProps> = ({
	visible,
	isLoading,
	disposedQty,
}) => {
	if (!visible) return null;
	if (isLoading || !disposedQty) return <SkeletonEmptyCard height={'50px'} />;
	return (
		<CardStandart sx={{ padding: '24px', height: '104px' }}>
			<Box
				display='flex'
				height='100%'
				flexDirection='column'
				justifyContent='space-between'
			>
				{/* 1 row */}

				<Row $justify='space-between'>
					<Row $gap='4px'>
						<TypographyPrimary16Bold
							sx={{
								color: disposedQty.count === 0 ? COLORS.icon : COLORS.black,
							}}
						>
							{disposedQty.count.toLocaleString()}
						</TypographyPrimary16Bold>
						{disposedQty.count !== 0 && (
							<ArrowCupidon
								iconColor={disposedQty.more ? COLORS.error : COLORS.success}
								direction={disposedQty.more ? 'up' : 'down'}
							/>
						)}
						<TypographySecondary>
							{morphForWords(disposedQty.count, ['голов', 'голова', 'голов'])}
						</TypographySecondary>
					</Row>
					<Row $justify='flex-end'>
						<CustomTooltip
							placement={'top'}
							width={'14px'}
							height={'14px'}
							info={false}
							title={
								'Сравнение производится за аналогичный период предыдущего месяца.'
							}
						/>
					</Row>
				</Row>
				{/* 2 row */}
				<Row $justify='space-between' $align='flex-end'>
					<TypographyPrimary>{'Рекомендованы\nна выбытие'}</TypographyPrimary>
					<TypographySecondary sx={{ textWrap: 'nowrap' }}>
						{`${moment(disposedQty.timestamp)
							.format('dddd, DD.MM.YYYY')
							.charAt(0)
							.toUpperCase()}${moment(disposedQty.timestamp)
							.format('dddd, DD.MM.YYYY')
							.slice(1)}`}
					</TypographySecondary>
				</Row>
			</Box>
		</CardStandart>
	);
};
