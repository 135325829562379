import { CssBaseline, ThemeProvider } from '@mui/material'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import store from './store'
import { fontTheme } from './styles/font'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'overlayscrollbars/css/OverlayScrollbars.min.css'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const theme = fontTheme
const queryClient = new QueryClient()

createRoot(document.getElementById('root')).render(
	<Provider store={store}>
		<SkeletonTheme baseColor='#F7F7F7' highlightColor='#fff'>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<App />
					</ThemeProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</SkeletonTheme>
	</Provider>
)
