export enum EFoodState {
	All = '',
	Normal = 'normal',
	Failed = 'failed',
	Empty = 'empty',
}

export enum GroupsStatusTabs {
	All = '',
	Milk = 'milk',
	Infirmary = 'infirmary',
	Birthplace = 'birthplace',
	Dry_1 = 'dry_1',
	Dry_2 = 'dry_2',
}
export enum GroupCowStatus {
	Milk = 'Дойные',
	Dry_1 = 'Сухостой 1',
	Dry_2 = 'Сухостой 2',
	Dry = 'Сухостой',
	Birthplace = 'Родилка',
	Infirmary = 'Лазарет',
}
export interface IResponseTabs {
	tabs: IGroupsPageTabs
}
export interface IGroupsPageTabs {
	milk: number
	infirmary: number
	birthplace: number
	dry_1: number
	dry_2: number
}
export interface IGroupEntity {
	id: number
	key: string
	barn: number
	barnkey: string
	barntitle: string
	food_dry_per_head: number
	previous_distribution_weight: number
	previous_cleaning_weight: number
	groupkey: string
	ration: number | undefined
	rationtitle: string
	title: string
	foodstate: EFoodState
	foodstatetitle: string
	feedcounter: number
	cattles_qty: number
	description?: string
	weight_per_head: number
	dry_matter_total?: number
	food_humidity?: number
	food_weight: number
	foodtable?: IGroupReachable
	correction_percent?: number
	last_distribution_dt?: number
	last_nudge_dt?: number
	ration_weight_per_head?: number
	empty_food_table_duration?: number
	plan_weight: number
	status?: GroupCowStatus
	cattles_limit?: number
	lactation_period?: string
	milk_two_days_ago_avg: null | number
	milk_two_days_ago_total: null | number
	milk_yesterday_avg: null | number
	milk_yesterday_total: null | number
	limp_cattle: null | number
	fat_cattle: null | number
}
export interface IGroupReachable {
	reachable_percent: number
	unreachable_percent: number
	total_percent: number
	no_data: boolean
}
export interface ILightGroupList {
	id: number
	title: string
	description: null | string
	cattles_qty: number
	weight_per_head: number
}

export interface IGroups {
	groupList: IGroupEntity[]
	lightGroupList: ILightGroupList[]
	currentGroup?: IGroupEntity
	loading: boolean
	error?: string
}

export interface ICameraData {
	label: string
	rfeed: number
	urfeed: number
	tfeed: number
}

export interface IGroupsAnalytics {
	lineBarData: ICameraData[]
	lineBarDataAggragated: ICameraData[]
	analyticsLoading: boolean
	analyticsError?: string
}

export interface IGroupApexChart {
	id?: number
	r_feed?: number
	start_dt: number
	t_feed: number
	ur_feed?: number
	density?: number
	status?: string
}
