import { Box } from '@mui/material'
import DonutChart from 'components/cattles/cattlesPage/shared/DonutChart'
import NoDataBlock from 'components/foodTable/shared/NoDataBlock'
import { morphForWords } from 'components/shared/processes'
import { Fatness } from 'models/IFarm'
import { CardStandart } from 'ui/CardStandart'
import { Row } from 'ui/Row'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface WidgetProps {
	visible: boolean
	fatness: Fatness[]
	isLoading: boolean
}

export const FatnessWidget: React.FC<WidgetProps> = ({
	visible,
	fatness,
	isLoading,
}) => {
	if (!visible) return null
	if (isLoading || !fatness)
		return <SkeletonEmptyCard height={'280px'} needTitle heightTitle={'24px'} />
	const isValidData = fatness.reduce((el, sum) => el + sum.value, 0)
	const donutOptions = fatness.map(el => el.value)
	const colors = fatness.map(el => el.color)
	return (
		<CardStandart sx={{ padding: '24px', height: '360px', width: '100%' }}>
			<TypographyHeader>Упитанность</TypographyHeader>
			{!isValidData ? (
				<NoDataBlock />
			) : (
				<Box
					sx={{
						display: 'flex',
						height: '100%',
						flexDirection: 'row',
						alignItems: 'center',
						// justifyContent: 'space-between',
						width: '100%',
						gap: '80px',
						paddingLeft: '20px',
						paddingBottom: '30px',
					}}
				>
					<DonutChart
						categories={donutOptions}
						colors={colors}
						width={'200px'}
						height={'200px'}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '24px',
						}}
					>
						{fatness.map(el => (
							<Box
								key={el.label}
								sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
							>
								<Row $gap='4px'>
									<span
										style={{
											width: '8px',
											height: '8px',
											backgroundColor: el.color,
											borderRadius: '50%',
										}}
									></span>
									<TypographySecondary14>{el.label}</TypographySecondary14>
								</Row>
								<Row $gap='4px' marginLeft='12px'>
									<TypographyPrimary>{el.value}</TypographyPrimary>
									<TypographySecondary>
										{morphForWords(el.value, ['голов', 'головы', 'голов'])}
									</TypographySecondary>
								</Row>
							</Box>
						))}
					</Box>
				</Box>
			)}
		</CardStandart>
	)
}
