import { styled } from '@mui/material';
import { IFarmStatistics } from 'models/IFarm';
import {
	CriticalFatnessWidget,
	DisposeRecommendedWidget,
	LamenessIssuesWidget,
} from './widgets';

interface WidgetProps {
	visible: boolean;
	className?: string;
	isLoading: boolean;
	statistics: IFarmStatistics;
}
const HealthBlockContainer = styled('div')`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	gap: 24px;
	width: 100%;
`;
export const HealthBlock: React.FC<WidgetProps> = ({
	visible,
	className,
	isLoading,
	statistics,
}) => {
	if (!visible) return null;

	return (
		<HealthBlockContainer className={className}>
			<CriticalFatnessWidget
				visible={true}
				critFatness={statistics?.crit_fatness}
				isLoading={isLoading}
			/>
			<DisposeRecommendedWidget
				visible={true}
				disposedQty={statistics?.dispose_recommended}
				isLoading={isLoading}
			/>
			<LamenessIssuesWidget
				visible={true}
				isLoading={isLoading}
				lamenessIssues={statistics?.limbs_issues}
			/>
		</HealthBlockContainer>
	);
};
