import { Box, CircularProgress, Collapse, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { ButtonUI } from '../../ui/buttons/ButtonUI';
import { UserEditField } from '../shared/UserEditField';
import SelectionField from '../shared/textFields/SelectionField';

import { useNavigate, useParams } from 'react-router-dom';
import AuthApiService from 'services/AuthApiService';
import { fetchAvailableRoles } from 'store/userSlice';
import { COLORS } from 'styles/colors';
import { CardStandart } from 'ui/CardStandart';
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14';

import { ButtonCancel } from 'ui/buttons/ButtonCancel';
import PhoneInput from 'ui/text-fields/phone-input/PhoneInput';
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold';
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg';
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { notifyUser } from '../../store/notificationsSlice';
import PasswordInput from '../../ui/PasswordInput';
import CreateRolePopup from './CreateRolePopup';
import RoleModelInfo from './RoleModelInfo';
import ErrorDialog from './utils/ErrorDialog';

type ErrorResponseType = {
	payload?: ErrorResponsePayloadType;
	type?: string;
};
type ErrorResponsePayloadType = {
	description: string;
	role_id?: number;
	title: string;
};

const SettingUser = () => {
	const dispatch = useAppDispatch();
	const params = useParams();
	const blankState = {
		role: -1,
		role_title: '',
		id: null,
		username: '',
		fullname: '',
		lastname: '',
		firstname: '',
		password: '',
		position: '',
		email: '',
		mobile_phone: '',
		superuser: false,
	};
	const [userData, setUserData] = useState(blankState);
	const [newPassword, setNewPassword] = useState('');
	const [serverErrorText, setServerErrorText] = useState('');
	const [showRoleInfo, setShowRoleInfo] = useState<boolean>(false);
	const [isCreateRolePopup, setIsCreateRolePopup] = useState<boolean>(false);
	const [openErrorPopup, setOpenErrorPopup] = useState<boolean>(false);
	const [downloadUserInfo, setDownloadUserInfo] = useState<boolean>(false);
	const [errorStateMessages, setErrorStateMessages] =
		useState<ErrorResponseType>({});
	const [initialState, setInitialState] = useState(blankState);
	const navigate = useNavigate();
	const { availableRoles, availableRolesPending } = useAppSelector(
		state => state.userReducer
	);

	const updateUserDataHandler = (field, value) => {
		const tmpData = { ...userData };
		tmpData[field] = value;

		setUserData(tmpData);
	};

	const [errorState, setErrorState] = useState({
		fullname: false,
		username: false,
		phoneNum: false,
		email: false,
		position: false,
	});
	const validators = {
		fullname: () => {
			const isError = userData.fullname.split(' ').length < 2;
			if (errorState.fullname !== isError) {
				setErrorState({ ...errorState, fullname: isError });
			}
		},
		email: () => {
			const emailValidate = userData.email.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);

			const isError = !emailValidate;
			if (errorState.email !== isError) {
				setErrorState({ ...errorState, email: isError });
			}
		},
		phoneNum: () => {
			const phoneValidate = userData.mobile_phone?.length > 7;
			const isError = !phoneValidate;
			if (errorState.phoneNum !== isError) {
				setErrorState({ ...errorState, phoneNum: isError });
			}
		},
		position: () => {
			const isError = userData.position.length < 2;
			if (errorState.position !== isError) {
				setErrorState({ ...errorState, position: isError });
			}
		},
	};
	useEffect(() => {
		if (availableRoles.length === 0) {
			dispatch(fetchAvailableRoles());
		}
	}, []);
	useEffect(() => {
		if (params.userId) {
			setDownloadUserInfo(true);
			updateUserDataHandler('id', params.userId);
			AuthApiService.getUserInfoById(params.userId)
				.then(response => {
					setUserData({
						...response.data,
						fullname: `${response.data.lastname} ${response.data.firstname}`,
						role: response.data.role
							? response.data.role
							: response.data.role_title,
						password: '',
					});
					setInitialState({
						...response.data,
						fullname: `${response.data.lastname} ${response.data.firstname}`,
						role: response.data.role
							? response.data.role
							: response.data.role_title,
						password: '',
					});
				})
				.finally(() => setDownloadUserInfo(false));
		}
	}, [params.userId]);

	const updateUserDataAtServer = (currentUserId: Number) => {
		const updateData = {
			lastname: userData.fullname.split(' ')[0],
			firstname: userData.fullname.split(' ')[1],
			role: userData.role === -1 ? null : userData.role,
			username: userData.mobile_phone.replace(/[^\d+]/g, ''),
			email: userData.email,
			position: userData.position,
			mobile_phone: userData.mobile_phone.replace(/[^\d+]/g, ''),
			password: newPassword,
		};
		if (typeof userData.role === 'string') {
			const matchedRole = availableRoles.find(
				role => role.title === userData.role.toString()
			);
			if (matchedRole) {
				updateData.role = matchedRole.id;
			}
		}
		if (!updateData.username) updateData.username = updateData.email;
		AuthApiService.putChangeUserData(updateData, currentUserId)
			.then(response => {
				dispatch(
					notifyUser({
						title: userData.fullname,
						description: 'Изменения успешно сохранены',
						isError: false,
					})
				);
				navigate('/settings/management', { replace: true });
			})
			.catch(error => {
				let errorText = '';
				if (error.response.data.password) {
					errorText += error.response.data.password[0];
				} else if (error.response.data.username) {
					errorText = 'Пользователь с таким номером телефона уже существует';
					setServerErrorText(errorText);
				} else if (error.response.data.email) {
					errorText += error.response.data.email[0];
				}
			});
	};

	const saveDataAtServer = () => {
		if (!userData.id) {
			const updateData = {
				username: '+' + userData.mobile_phone.replace(/[^\d+]/g, ''),
				password: newPassword,
			};
			AuthApiService.postNewUser(updateData)
				.then(response => {
					updateUserDataHandler('id', response.data.id);
					updateUserDataAtServer(response.data.id);
				})
				.catch(error => {
					let errorText = '';
					if (error.response.data.password) {
						errorText += error.response.data.password[0];
					} else if (error.response.data.username) {
						errorText = 'Пользователь с таким номером телефона уже существует';
					} else if (error.response.data.email) {
						errorText += error.response.data.email[0];
					}
					setServerErrorText(errorText);
				});
		} else {
			updateUserDataAtServer(userData.id);
		}

		if (params.userId && newPassword) {
			const updateData = {
				id: userData.id,
				password: newPassword,
			};
			AuthApiService.postForcePassword(updateData)
				.then(responce => {
					if (responce.status === 202) {
						dispatch(
							notifyUser({
								title: userData.fullname,
								description: 'Пароль пользователя успешно изменен',
								isError: false,
							})
						);
						navigate('/settings/management');
					}
				})
				.catch(error => {
					let errorText = '';
					try {
						errorText += error.response.data.password[0];
					} catch {}
					dispatch(
						notifyUser({
							title: userData.fullname,
							description: errorText
								? errorText
								: 'Ошибка при изменении пароля пользователя',
							isError: true,
						})
					);
				});
		}
	};
	const changeCurrentRole = () => {
		setOpenErrorPopup(false);
		const newRoleTitle = availableRoles.find(
			roleId => roleId.id === errorStateMessages.payload.role_id
		);
		updateUserDataHandler('role', newRoleTitle.title);
	};
	const returnAtEditCreateRoleForm = () => {
		setOpenErrorPopup(false);
		setIsCreateRolePopup(true);
	};

	const returnAtEditEditUserForm = () => {
		setOpenErrorPopup(false);
	};

	const confirmExitWithoutChanges = () => {
		setOpenErrorPopup(false);
		navigate('/settings/management');
	};

	const exitWithoutChanges = () => {
		const isSame = JSON.stringify(initialState) === JSON.stringify(userData);
		if (isSame) {
			navigate('/settings/management');
		} else {
			const messageForErrorDialog = {
				payload: {
					description: 'Внесенные изменения будут утеряны. ',
					title: 'Выйти без сохранения',
				},
				type: 'exit_without_changes',
			};
			setErrorStateMessages(messageForErrorDialog);
			setOpenErrorPopup(true);
		}
	};
	return (
		<Box sx={{ margin: '0px 14px' }}>
			<CardStandart sx={{ padding: '24px', minHeight: '600px' }}>
				<TypographyPrimary16Bold>Общая информация</TypographyPrimary16Bold>
				{availableRolesPending || downloadUserInfo ? (
					<Box
						sx={{
							marginTop: '20%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyItems: 'center',
						}}
					>
						<CircularProgress
							sx={{
								'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
							}}
						/>
					</Box>
				) : (
					<>
						<Box display='flex' flexDirection='row' gap='16px' mt='16px'>
							<UserEditField
								isErrorFocus={errorState.fullname}
								onBlur={() => validators.fullname()}
								onFocus={() => {
									setErrorState({ ...errorState, fullname: false });
								}}
								maxLength={50}
								helperText='Укажите Фамилию и Имя'
								value={`${userData.fullname}`}
								id={`userFirstLastName${params.userId}`}
								label='Фамилия Имя'
								onChange={e => {
									updateUserDataHandler('fullname', e.target.value);
								}}
							/>
							<UserEditField
								isErrorFocus={errorState.position}
								onBlur={() => validators.position()}
								onFocus={() => {
									setErrorState({ ...errorState, position: false });
								}}
								helperText='Укажите должность'
								key={`userPosition${params.userId}`}
								value={userData.position}
								id='position'
								label='Должность'
								onChange={e =>
									updateUserDataHandler('position', e.target.value)
								}
							/>
						</Box>

						<Box display='flex' flexDirection='row' gap='16px'>
							<UserEditField
								isErrorFocus={errorState.email}
								onBlur={() => validators.email()}
								onFocus={() => {
									setErrorState({ ...errorState, email: false });
								}}
								helperText='Укажите электронную почту'
								value={userData.email}
								id={`useremail${params.userId}`}
								isError={!params.userId && !userData.email}
								label='Электронная почта'
								onChange={e => updateUserDataHandler('email', e.target.value)}
							/>
							{/* <PhoneEditField
								isErrorFocus={errorState.phoneNum}
								onBlur={() => validators.phoneNum()}
								onFocus={() => {
									setErrorState({ ...errorState, phoneNum: false })
								}}
								error={serverErrorText}
								helperText='Укажите номер телефона'
								marginLeft='24px'
								value={userData.mobile_phone}
								id={`telep${params.userId}`}
								label='Номер телефона'
								setServerErrorText={setServerErrorText}
								onChange={value => updateUserDataHandler('mobile_phone', value)}
								/> */}
							<PhoneInput
								id={`telep${params.userId}`}
								onChange={value => updateUserDataHandler('mobile_phone', value)}
								name={'phone'}
								value={userData.mobile_phone}
								onBlur={() => validators.phoneNum()}
								width={'500px'}
								dropDownWidth={'500px'}
								isError={Boolean(serverErrorText)}
								helperText={
									serverErrorText ? serverErrorText : 'Укажите номер телефона'
								}
								isShowHelperText={errorState.phoneNum}
								sx={{ marginTop: '6px' }}
							/>
						</Box>
						<TypographyPrimary16Bold
							sx={{
								pt: '12px',
							}}
						>
							{`${params.userId ? 'Смена' : 'Создание'} пароля`}
						</TypographyPrimary16Bold>
						<Box sx={{ width: '500px' }}>
							<PasswordInput
								id={'newPassword'}
								setNewPassword={setNewPassword}
								newPassword={newPassword}
								label={'Новый пароль'}
								needTooltip
							/>
						</Box>
						<Divider sx={{ pt: '32px' }} />
						<TypographyPrimary16Bold
							sx={{
								pt: '24px',
							}}
						>
							Выбор роли
						</TypographyPrimary16Bold>
						<Box
							sx={{
								mt: '16px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '16px',
							}}
						>
							<SelectionField
								roleModel
								id={`userRole${params.userId}`}
								choiceList={availableRoles}
								currentValue={userData.role}
								onChange={value => updateUserDataHandler('role', value)}
								onClick={() => setIsCreateRolePopup(true)}
								width={500}
								inputLabel='Роль пользователя'
							/>
							{userData.role !== -1 && (
								<Box
									display='flex'
									flexDirection='row'
									gap='8px'
									alignItems='center'
									onClick={() => setShowRoleInfo(prevState => !prevState)}
									sx={{ cursor: 'pointer' }}
								>
									<TypographySecondary14>
										Просмотреть функционал роли
									</TypographySecondary14>
									{showRoleInfo ? (
										<ArrowOpenDone />
									) : (
										<ArrowCloseDone stroke={COLORS.icon} />
									)}
								</Box>
							)}
						</Box>
						<Collapse in={showRoleInfo} timeout='auto' unmountOnExit>
							<RoleModelInfo
								choiceList={availableRoles}
								currentValue={userData.role}
							/>
						</Collapse>
						<CreateRolePopup
							open={isCreateRolePopup}
							setIsCreateRolePopup={setIsCreateRolePopup}
							changeCurrentRole={changeCurrentRole}
							setErrorStateMessages={setErrorStateMessages}
							setOpenErrorPopup={setOpenErrorPopup}
						/>
					</>
				)}
			</CardStandart>
			<Box
				display='flex'
				justifyContent='flex-end'
				alignItems='center'
				mt='24px'
			>
				<ButtonCancel
					onClick={exitWithoutChanges}
					sx={{
						padding: '0px',
						margin: '0px',
						width: '220px',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '17px',
						textTransform: 'none',
					}}
				>
					Выйти без сохранения
				</ButtonCancel>
				<ButtonUI
					sx={{ height: '56px', margin: '0px' }}
					onClick={() => saveDataAtServer()}
					disabled={
						(!params.userId && !newPassword) ||
						userData.role === null ||
						userData.role === -1 ||
						!userData.fullname ||
						userData.fullname.trim().split(' ').length !== 2 ||
						!userData.email ||
						!userData.position ||
						(newPassword.length !== 0 &&
							newPassword.length < 8 &&
							/^[a-zа-я\d]*$/.test(newPassword) &&
							!/^[A-Za-z0-9!-@#$%.^,&*()_{}[\];:"/|^]+$/.test(newPassword))
					}
				>
					{'Сохранить'}
				</ButtonUI>
			</Box>
			<ErrorDialog
				openErrorPopup={openErrorPopup}
				title={errorStateMessages.payload?.title}
				description={errorStateMessages.payload?.description}
				changeCurrentRole={changeCurrentRole}
				returnAtEditCreateRoleForm={returnAtEditCreateRoleForm}
				type={errorStateMessages.type}
				returnAtEditEditUserForm={returnAtEditEditUserForm}
				confirmExitWithoutChanges={confirmExitWithoutChanges}
			/>
		</Box>
	);
};

export default SettingUser;
