import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 24px;
  width: 100%;

  & > .full-width {
    grid-column: 1 / -1;
  }
`;