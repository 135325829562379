import { Box, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { useEffect } from 'react'

import { fetchFeedPlanData } from '../../store/feedPlanSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { Outlet } from 'react-router-dom'
import { fetchFarmStatistics } from 'store/farmSlice'
import { selectPermissions } from '../../store/authSlice'
import { fontTheme } from '../../styles/font'
import { Container } from './styles'
import {
	FatnessWidget,
	LamenessWidget,
	MilkingWidget,
	StructureOfHerd,
	TaskWidget,
} from './ui'
import FoodTableInfo from './ui/FoodTableInfo'
import { FoodTableBlock } from './ui/food-table-block'
import { HealthBlock } from './ui/health-block'

const theme = fontTheme

const FeedGroupsPage = () => {
	const dispatch = useAppDispatch()
	const permissions = useAppSelector(selectPermissions)
	const { statistics, loading, error } = useAppSelector(
		state => state.farmReducer
	)
	useEffect(() => {
		!statistics && dispatch(fetchFarmStatistics())
		dispatch(fetchFeedPlanData({}))
	}, [dispatch, statistics])

	const { isShowWeightNotif } = useAppSelector(
		store => store.notificationsSlice
	)

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{
					width: '100%',
					fontFamily: theme.typography.fontFamily,
					pl: '8px',
					pr: '20px',
				}}
			>
				{/* {permissions && permissions.can_edit_cattle_groups && (
					<Collapse in={isShowWeightNotif} timeout='auto' unmountOnExit>
						<GroupHeadPopup />
					</Collapse>
				)} */}
				<Container sx={{ marginTop: '24px' }}>
					<StructureOfHerd
						visible={true}
						herd={statistics?.herd}
						isLoading={loading}
					/>
					<MilkingWidget
						visible={true}
						milking={statistics?.milkings}
						isLoading={loading}
					/>
					<TaskWidget
						visible={true}
						tasks={statistics?.tasks}
						isLoading={loading}
					/>
					<FoodTableBlock
						className='full-width'
						visible={true}
						statistics={statistics}
						isLoading={loading}
					/>
				</Container>
				<FoodTableInfo />
				<Container>
					<FatnessWidget
						visible={true}
						fatness={statistics?.fatness}
						isLoading={loading}
					/>
					<LamenessWidget
						visible={true}
						lameness={statistics?.limpness}
						isLoading={loading}
					/>
					<HealthBlock
						visible={true}
						statistics={statistics}
						isLoading={loading}
					/>
				</Container>
			</Box>
			<Outlet />
		</ThemeProvider>
	)
}

export default FeedGroupsPage
