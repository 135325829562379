import { Box } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/plan_edit_plus_logo.svg';
import { forwardRef, useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import { COLORS } from 'styles/colors';
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14';

export const ListboxComponent = forwardRef<HTMLDivElement, any>(
	function ListboxComponent(props, ref) {
		const {
			children,
			onClickListButton,
			isGrouped,
			textDescriptionForClickButton,
			...other
		} = props;
		// Достаём массив элементов из props.children
		const items: { type: 'group' | 'option'; content: any }[] = [];
		children.forEach(child => {
			if (isGrouped) {
				// Если это группа, добавляем заголовок группы
				items.push({ type: 'group', content: child.props.children[0] });
				// Добавляем элементы группы
				child.props.children[1]?.forEach((item: any) =>
					items.push({ type: 'option', content: item })
				);
			} else {
				items.push({ type: 'option', content: child?.props?.children });
			}
		});
		// Реф для хранения высот строк
		const listRef = useRef<VariableSizeList>(null);
		const rowHeights = useRef<number[]>([]);

		// Функция для определения высоты строки
		const getRowHeight = (index: number) => rowHeights.current[index] || 50;

		// Функция для обновления высоты строки
		const setRowHeight = (index: number, size: number) => {
			rowHeights.current[index] = size;
			if (listRef.current) {
				listRef.current.resetAfterIndex(index); // Обновление кэша для корректного отображения
			}
		};

		// Компонент для строки с динамической высотой
		const Row = ({
			index,
			style,
		}: {
			index: number;
			style: React.CSSProperties;
		}) => {
			const rowRef = useRef<HTMLDivElement>(null);

			useEffect(() => {
				if (rowRef.current) {
					const height = rowRef.current.getBoundingClientRect().height;
					if (rowHeights.current[index] !== height) {
						setRowHeight(index, height);
					}
				}
			}, [index]);

			const item = isGrouped ? items[index] : items[0].content[index];
			return (
				<div
					ref={rowRef}
					style={{
						...style,
						height: 'auto',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{isGrouped && item?.type === 'group' ? (
						<Box
							sx={{
								backgroundColor: COLORS.background,
								color: COLORS.secondary,
							}}
						>
							{item?.content}
						</Box>
					) : isGrouped ? (
						item.content
					) : (
						item
					)}
				</div>
			);
		};

		return (
			<div
				ref={ref}
				{...other}
				style={{
					position: 'relative',
					overflow: 'hidden',
					height: 200,
					width: '100%',
				}}
			>
				<VariableSizeList
					ref={listRef}
					height={200} // Высота области прокрутки
					width='100%' // Ширина списка
					itemSize={getRowHeight} // Функция для определения высоты строки
					itemCount={isGrouped ? items.length : items[0].content.length} // Количество элементов
					overscanCount={5} // Количество дополнительных элементов для рендера вне области видимости
				>
					{({ index, style }) => <Row index={index} style={style} />}
				</VariableSizeList>
				{onClickListButton && (
					<Box
						onClick={() => onClickListButton()}
						sx={{
							zIndex: '14000',
							position: 'fixed',
							bottom: '10px',
							left: '10px',
							width: '90%',
							backdropFilter: 'blur(2px)',
							borderRadius: '8px',
							background: 'rgba(255, 255, 255, 0.70)',
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						<PlusIcon height='24px' width='24px' stroke={COLORS.secondary} />
						<TypographySecondary14>
							{textDescriptionForClickButton}
						</TypographySecondary14>
					</Box>
				)}
			</div>
		);
	}
);
