import { IHoofHealthTable } from 'models/ICattlesData'

export const updateHoofDataCounts = (data: IHoofHealthTable[], hoofData) => {
	const updatedHoofData = hoofData.map(item => ({
		...item,
		count: 0,
	}))

	data.forEach(exam => {
		exam.diagnosis?.forEach(diagnose => {
			const hoof = diagnose?.position
			const hoofItem = updatedHoofData.find(item => item.key === hoof)
			if (hoofItem) {
				hoofItem.count += 1
			}
			const allItem = updatedHoofData.find(item => item.key === 'all')
			if (allItem) {
				allItem.count += 1
			}
		})
	})
	return updatedHoofData
}
const transformToHorizontal = (src: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = src
		img.crossOrigin = 'Anonymous' // Для избежания проблем с CORS

		img.onload = () => {
			const canvas = document.createElement('canvas')
			const context = canvas.getContext('2d')

			if (context) {
				// Меняем ширину и высоту для поворота
				canvas.width = img.height
				canvas.height = img.width

				// Поворачиваем canvas на 90 градусов по часовой стрелке
				context.translate(canvas.width / 2, canvas.height / 2)
				context.rotate((90 * Math.PI) / 180)
				context.drawImage(img, -img.width / 2, -img.height / 2)

				// Возвращаем результат в виде base64
				resolve(canvas.toDataURL('image/jpeg'))
			} else {
				reject('Canvas context is not supported')
			}
		}

		img.onerror = () => {
			reject('Failed to load the image')
		}
	})
}

export const processImages = async (images: { image: string }[]) => {
	const processedImages = await Promise.all(
		images.map(async image => {
			const img = new Image()
			img.src = image.image

			return new Promise(resolve => {
				img.onload = async () => {
					const isVertical = img.height > img.width // Проверяем ориентацию
					if (isVertical) {
						const transformedImage = await transformToHorizontal(image.image)
						resolve({ ...image, image: transformedImage })
					} else {
						resolve(image) // Горизонтальные оставляем без изменений
					}
				}

				img.onerror = () => resolve(image) // В случае ошибки возвращаем оригинал
			})
		})
	)

	return processedImages
}
