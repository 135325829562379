import { IFiltersLamenessTable } from 'components/cattles/lamenessPage/shared/models';
import useIsEqual from 'hook/useIsEqual';
import { LamenessStorageValue } from 'models/IConstants';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CattlesApiService from 'services/cattlesApiServices';

const defaultInitialState: IFiltersLamenessTable = {
	tags: [],
	lameness: {
		statuses: [],
		startOf: '',
		endOf: '',
	},
	treatmentStatus: {
		statuses: [],
		startOf: '',
		endOf: '',
	},
	inspectionStatus: {
		statuses: [],
		startOf: null,
		endOf: null,
	},
	diagnoses: {
		ids: [],
	},
	cowAge: {
		startOf: '',
		endOf: '',
	},
	groups: {
		ids: [],
	},
	lactation: {
		statuses: [],
		startOf: '',
		endOf: '',
	},
	fatness: {
		startOf: '',
		endOf: '',
	},
	examiners: {
		ids: [],
	},
	lastTrim: {
		startOf: '',
		endOf: '',
	},
	responsiblePersons: {
		ids: [],
	},
	isLateTrims: false,
	dateRange: {
		startDate: null,
		endDate: null,
	},
};
const useLamenessFilter = () => {
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [isFetchComplete, setIsFetchComplete] = useState<boolean>(false);
	const location = useLocation();

	const isLamenessPage = location.pathname === '/cattles/lameness';
	const sessionStorageData = isLamenessPage
		? sessionStorage.getItem(LamenessStorageValue.LamenessFilterValue)
		: sessionStorage.getItem(LamenessStorageValue.TrimFilterValue);

	const [activeFilters, setActiveFilters] =
		useState<IFiltersLamenessTable>(undefined);
	const [availableFilters, setAvailableFilters] =
		useState<IFiltersLamenessTable>(defaultInitialState);

	useEffect(() => {
		CattlesApiService.getAvailableFilterValues()
			.then(res => {
				const { limpness, age, examination, lactation, treatment, last_trim } =
					res.data;
				const availableData = {
					...defaultInitialState,
					lameness: {
						...defaultInitialState.lameness,
						startOf: limpness.start,
						endOf: limpness.end,
					},
					cowAge: {
						...defaultInitialState.cowAge,
						startOf: age.start ? age.start : '',
						endOf: age.end ? age.end : '',
					},
					inspectionStatus: {
						...defaultInitialState.inspectionStatus,
						startOf: examination.start === '' ? null : examination.start,
						endOf: examination.end === '' ? null : examination.end,
					},
					lactation: {
						...defaultInitialState.lactation,
						startOf: lactation.start,
						endOf: lactation.end,
					},
					treatmentStatus: {
						...defaultInitialState.treatmentStatus,
						startOf: treatment.start,
						endOf: treatment.end,
					},
					lastTrim: {
						startOf: last_trim.start,
						endOf: last_trim.end,
					},
				};
				setAvailableFilters(availableData);
				setActiveFilters(JSON.parse(sessionStorageData) || defaultInitialState);
			})
			.catch(error => console.error(error))
			.finally(() => setIsFetchComplete(true));
	}, []);

	const toggleFilter = useCallback(
		(
			property: string,
			key: string,
			replaceProp?: string,
			keyForReplace?: string[]
		) => {
			setActiveFilters(prev => {
				const isKeyPresent = prev[property].statuses.includes(key);
				const updatedStatuses = isKeyPresent
					? prev[property].statuses.filter(status => status !== key)
					: [...prev[property].statuses, key];

				const updatedReplaceStatuses =
					replaceProp && keyForReplace
						? keyForReplace.every(k => prev[replaceProp].statuses.includes(k))
							? prev[replaceProp].statuses.filter(
									status => !keyForReplace.includes(status)
							  )
							: [...prev[replaceProp].statuses, ...keyForReplace]
						: [];

				if (replaceProp) {
					return {
						...prev,
						[replaceProp]: {
							...prev[replaceProp],
							statuses: updatedReplaceStatuses,
						},
						[property]: {
							...prev[property],
							statuses: updatedStatuses,
						},
					};
				} else {
					return {
						...prev,
						[property]: {
							...prev[property],
							statuses: updatedStatuses,
						},
					};
				}
			});
		},
		[]
	);

	const handleTabs = (property, key) => {
		returnInitialProps('all');
		setActiveFilters(prev => {
			return {
				...prev,
				[property]: {
					...prev[property],
					statuses: [key],
				},
			};
		});
	};

	const handleDayChange = useCallback((e, property, key) => {
		if (!e || !e.target) return;
		const newValue = e.target.value.replace(/[^0-9-]/g, '');
		const isValid = /^[0-9-]*$/.test(newValue);
		setActiveFilters(prev => ({
			...prev,
			[property]: {
				...prev[property],
				[key]: isValid ? newValue : e.target.value,
			},
		}));
	}, []);

	const returnInitialProps = useCallback(
		property => {
			if (property === 'all') {
				setActiveFilters(defaultInitialState);
			} else {
				setActiveFilters(prev => ({
					...prev,
					[property]: {
						...defaultInitialState[property],
					},
				}));
			}
		},
		[defaultInitialState]
	);

	const turnOnFilters = (property: string, keys: string[]) => {
		setActiveFilters(prev => {
			return {
				...prev,
				[property]: {
					...prev[property],
					statuses: [...prev[property].statuses, ...keys],
				},
			};
		});
	};

	useEffect(() => {
		const json = JSON.stringify(activeFilters);

		if (sessionStorageData !== json) {
			isLamenessPage
				? sessionStorage.setItem(LamenessStorageValue.LamenessFilterValue, json)
				: sessionStorage.setItem(LamenessStorageValue.TrimFilterValue, json);
		}
	}, [activeFilters]);

	const isEqualFilter = useIsEqual(defaultInitialState, activeFilters);
	const isEqualLamenessFilter = useIsEqual(
		defaultInitialState.lameness,
		activeFilters?.lameness
	);
	const isEqualTreatmentFilter = useIsEqual(
		defaultInitialState.treatmentStatus,
		activeFilters?.treatmentStatus
	);
	const isEqualInspectionFilter = useIsEqual(
		defaultInitialState.inspectionStatus,
		activeFilters?.inspectionStatus
	);
	const isEqualLactationFilter = useIsEqual(
		defaultInitialState.lactation,
		activeFilters?.lactation
	);
	const isEqualCowAgeFilter = useIsEqual(
		defaultInitialState.cowAge,
		activeFilters?.cowAge
	);
	const isEqualGroupsFilter = useIsEqual(
		defaultInitialState.groups,
		activeFilters?.groups
	);
	const isEqualFatnessFilter = useIsEqual(
		defaultInitialState.fatness,
		activeFilters?.fatness
	);
	const isEqualExaminersFilter = useIsEqual(
		defaultInitialState.examiners,
		activeFilters?.examiners
	);
	const isEqualDiagnosisFilter = useIsEqual(
		defaultInitialState.diagnoses,
		activeFilters?.diagnoses
	);
	const isEqualTrimFilter = useIsEqual(
		defaultInitialState.lastTrim,
		activeFilters?.lastTrim
	);
	const isEqualRespPersonsFilter = useIsEqual(
		defaultInitialState.responsiblePersons,
		activeFilters?.responsiblePersons
	);

	return {
		activeFilters,
		availableFilters,
		setActiveFilters,
		openFilter,
		setOpenFilter,
		toggleFilter,
		handleDayChange,
		returnInitialProps,
		turnOnFilters,
		handleTabs,
		isFetchComplete,
		isEqualFilter,
		isEqualLamenessFilter,
		isEqualTreatmentFilter,
		isEqualInspectionFilter,
		isEqualLactationFilter,
		isEqualCowAgeFilter,
		isEqualGroupsFilter,
		isEqualFatnessFilter,
		isEqualExaminersFilter,
		isEqualDiagnosisFilter,
		isEqualTrimFilter,
		isEqualRespPersonsFilter,
	};
};

export default useLamenessFilter;
