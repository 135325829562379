import { Link, styled } from '@mui/material';
import { COLORS } from 'styles/colors';


export const StyledLink = styled(Link)`
	padding: 7.5px 14px;
	margin: 0px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;

	&:hover {
		border-radius: 12px;
		box-shadow: ${COLORS.shadowHoverSecondary};
	}
`