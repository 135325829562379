import Tooltip from '@mui/material/Tooltip/Tooltip'
import { useState } from 'react'
import { ReactComponent as AttentionLogo } from '../../assets/barchart_info_logo.svg'
import { ReactComponent as CircleHelpLogo } from '../../assets/icons/circle-help.svg'
import { COLORS } from '../../styles/colors'

const CustomTooltip = props => {
	const { placement, title, width, height, info } = props

	const [open, setOpen] = useState(false)

	const handleToggle = () => setOpen(prev => !prev)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	return (
		<Tooltip
			placement={placement}
			arrow
			componentsProps={{
				arrow: {
					sx: {
						boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
						color: '#fff',
					},
				},
				tooltip: {
					sx: {
						width: 'max-content',
						padding: '10px',
						boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
						backgroundColor: 'white',
						borderRadius: '8px',
						color: COLORS.secondary,
						fontFamily: 'Montserrat',
						fontWeight: '400',
						fontSize: '12px',
					},
				},
			}}
			open={open}
			onOpen={handleOpen}
			onClose={handleClose}
			disableFocusListener
			disableTouchListener
			title={title}
		>
			<div
				onMouseEnter={handleOpen}
				onMouseLeave={handleClose}
				onClick={handleToggle}
				style={{ cursor: 'pointer' }}
			>
				{info ? (
					<CircleHelpLogo
						fill={open ? COLORS.main : COLORS.secondaryFont}
						style={{
							width: width,
							height: height,
						}}
					/>
				) : (
					<AttentionLogo
						style={{
							color: open ? COLORS.main : COLORS.secondaryFont,
							width: width,
							height: height,
						}}
					/>
				)}
			</div>
		</Tooltip>
	)
}

export default CustomTooltip
