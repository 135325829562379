import { Box } from '@mui/material';
import { Tasks } from 'models/IFarm';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/colors';
import { CardStandart } from 'ui/CardStandart';
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard';
import { TypographyHeader } from 'ui/typography/TypographyHeader';
import { TypographyPrimary } from 'ui/typography/TypographyPrimary';
import { TypographyPrimary14Bold } from 'ui/typography/TypographyPrimary14Bold';
import { StyledLink } from './styles';

interface WidgetProps {
	visible: boolean;
	tasks: Tasks;
	isLoading: boolean;
}

export const TaskWidget: React.FC<WidgetProps> = ({
	visible,
	tasks,
	isLoading,
}) => {
	const navigate = useNavigate();
	if (!visible) return null;
	if (isLoading || !tasks)
		return (
			<SkeletonEmptyCard height={'280px'} needTitle heightTitle={'24px'} />
		);

	const tasksOptions = [
		{
			label: 'План кормления',
			value: tasks.feed_plan,
			link: '/feed/plan',
		},
		{
			label: 'Хромота',
			value: tasks.limpness,
			link: '/cattles/lameness',
		},
		{
			label: 'Обрезка',
			value: tasks.trims,
			link: '/cattles/trim',
		},
		{
			label: 'Идентификация',
			value: tasks.identification,
			link: '/cattles',
		},
	];
	return (
		<CardStandart sx={{ padding: '24px 0px', height: '360px', width: '100%' }}>
			<TypographyHeader sx={{ padding: '0px 24px' }}>Задачи</TypographyHeader>
			<Box display='flex' flexDirection='column' mt='49px' gap='8px'>
				{tasksOptions.map(task => (
					<StyledLink onClick={() => navigate(task.link)} key={task.link}>
						<TypographyPrimary>{task.label}</TypographyPrimary>
						<TypographyPrimary14Bold sx={{ color: COLORS.secondary }}>
							{task.value}
						</TypographyPrimary14Bold>
					</StyledLink>
				))}
			</Box>
		</CardStandart>
	);
};
