import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import FarmApiService from 'services/farmApiServices';
import { IFarmStatistics } from '../models/IFarm';

interface FarmState {
  statistics: IFarmStatistics | null;
  loading: boolean;
  error: string | null;
}

const initialState: FarmState = {
  statistics: null,
  loading: false,
  error: null,
};

export const fetchFarmStatistics = createAsyncThunk<
  IFarmStatistics,
  void,
  { rejectValue: string }
>('farm/fetchStatistics', async function (_, { rejectWithValue }) {
  try {
    const response = await FarmApiService.getFarmStatistic();
    if (response.status !== 200) {
      throw new Error('HTTP request error!');
    }
    return response.data;
  } catch (error) {
    let errorMessage = 'Failed to retrieve farm statistics!';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return rejectWithValue(errorMessage);
  }
});

export const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchFarmStatistics.fulfilled,
        (state, action: PayloadAction<IFarmStatistics>) => {
          state.loading = false;
          state.error = null;
          state.statistics = action.payload;
        }
      )
      .addCase(fetchFarmStatistics.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statistics = null;
      })
      .addCase(
        fetchFarmStatistics.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || 'Unknown error occurred';
          state.statistics = null;
        }
      );
  },
});

export default farmSlice.reducer;