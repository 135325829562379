import { Box } from '@mui/material'
import DonutChart from 'components/cattles/cattlesPage/shared/DonutChart'
import NoDataBlock from 'components/foodTable/shared/NoDataBlock'
import CustomTooltip from 'components/shared/CustomTooltip'
import { morphForWords } from 'components/shared/processes'
import { Herd } from 'models/IFarm'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { Row } from 'ui/Row'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface WidgetProps {
	visible: boolean
	herd: Herd
	isLoading: boolean
}

export const StructureOfHerd: React.FC<WidgetProps> = ({
	visible,
	herd,
	isLoading,
}) => {
	if (!visible) return null
	if (isLoading || !herd)
		return <SkeletonEmptyCard height={'280px'} needTitle heightTitle={'24px'} />
	const timeStamp = `${moment(herd.timestamp)
		.format('dddd, DD.MM.YYYY')
		.charAt(0)
		.toUpperCase()}${moment(herd.timestamp)
		.format('dddd, DD.MM.YYYY')
		.slice(1)}`
	const donutOptions = [herd.milky, herd.dry]
	const colors = [COLORS.main, COLORS.mainOpacity]
	const totalCattles = herd.milky + herd.dry

	const descriptionOption = [
		{
			value: herd.milky,
			label: 'Дойные',
			percent: (herd.milky / totalCattles) * 100,
			color: COLORS.main,
		},
		{
			value: herd.dry,
			label: 'Сухостойные',
			percent: (herd.dry / totalCattles) * 100,
			color: COLORS.mainOpacity,
		},
	]

	return (
		<CardStandart sx={{ padding: '24px', height: '360px', width: '100%' }}>
			<Row $justify='space-between'>
				<TypographyHeader>Структура стада</TypographyHeader>
				<CustomTooltip
					placement={'bottom'}
					width={'14px'}
					height={'14px'}
					info={false}
					title={
						'Количество дойных и сухостойных коров рассчитано исходя из установленных статусов групп по типу содержания.'
					}
				/>
			</Row>
			<TypographySecondary14 mt={'8px'}>{timeStamp}</TypographySecondary14>
			{!totalCattles ? (
				<NoDataBlock />
			) : (
				<Box
					sx={{
						display: 'flex',
						height: '100%',
						flexDirection: 'row',
						alignItems: 'center',
						width: '100%',
						gap: '80px',
						paddingLeft: '20px',
						paddingBottom: '50px',
					}}
				>
					<DonutChart
						categories={donutOptions}
						colors={colors}
						width={'200px'}
						height={'200px'}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '24px',
						}}
					>
						{descriptionOption.map(el => (
							<Box
								key={el.label}
								sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
							>
								<Row $gap='4px'>
									<span
										style={{
											width: '8px',
											height: '8px',
											backgroundColor: el.color,
											borderRadius: '50%',
										}}
									></span>
									<TypographySecondary14>{el.label}</TypographySecondary14>
								</Row>
								<Row $gap='4px'>
									<TypographyPrimary>{el.value}</TypographyPrimary>
									<TypographySecondary>{`${morphForWords(el.value, [
										'голов',
										'головы',
										'голов',
									])} /`}</TypographySecondary>
									<TypographyPrimary>{el.percent}</TypographyPrimary>
									<TypographySecondary>{'%'}</TypographySecondary>
								</Row>
							</Box>
						))}
					</Box>
				</Box>
			)}
		</CardStandart>
	)
}
