import { Box, Divider, IconButton } from '@mui/material';
import { ReactComponent as DownloadLogo } from 'assets/analytics_download_logo.svg';
import TabsFilter from 'components/shared/breadCrumbs/TabsFilter';
import AutocompleteInput from 'components/shared/textFields/AutocompleteInput';
import { useExportCowsListReport } from 'hook/useExportLamenessReport';
import useLamenessFilter from 'hook/useLamenessFilter';
import useCowsNextPage from 'hook/useNextPage';
import { useState } from 'react';
import { COLORS } from 'styles/colors';
import { CardStandart } from 'ui/CardStandart';
import CustomPagination from 'ui/CustomPagination';
import Preloader from 'ui/Preloader';
import { TypographyHeader } from 'ui/typography/TypographyHeader';
import { drawerWidth } from 'variables';
import LamenessTable from '../shared/LamenessTable';
import FilterContent from '../shared/filterElements/FilterContent';
import { InspectionStatus, TreatmentStatus } from '../shared/models';
import { titleForLamenessFilter } from '../shared/processes';

const LamenessList = () => {
	const [page, setPage] = useState<number>(1);
	const initialPageSize = 36;
	const {
		activeFilters,
		availableFilters,
		openFilter,
		toggleFilter,
		setActiveFilters,
		setOpenFilter,
		handleDayChange,
		returnInitialProps,
		turnOnFilters,
		handleTabs,
		isFetchComplete,
		isEqualFilter,
		isEqualLamenessFilter,
		isEqualTreatmentFilter,
		isEqualInspectionFilter,
		isEqualLactationFilter,
		isEqualCowAgeFilter,
		isEqualGroupsFilter,
		isEqualFatnessFilter,
		isEqualExaminersFilter,
		isEqualDiagnosisFilter,
	} = useLamenessFilter();

	const propForTable = {
		params: activeFilters,
		isFetchComplete,
		page,
		setPage,
		initialPageSize,
		isFetchCowsListEnabled: !openFilter,
		initialTabs: initialTabs,
	};

	const {
		cowsList,
		totalCountPages,
		fetchingCowsList,
		availableTags,
		availableTabs,
	} = useCowsNextPage(propForTable);

	const propsForReport = {
		params: activeFilters,
		page,
		initialPageSize,
	};
	const { isFetchReport, setIsFetchReport } =
		useExportCowsListReport(propsForReport);

	// useEffect(() => {
	// 	setPage(1);
	// }, [activeFilters]);
	return (
		<CardStandart
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '24px',
				overflowX: 'auto',
				width: `calc(100vw - ${drawerWidth}px - 80px)`,
			}}
		>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<TypographyHeader>Список голов</TypographyHeader>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'14px'}
				>
					<AutocompleteInput
						label={'Поиск по номеру бирки'}
						widthForInput={'400px'}
						isCheckbox
						availableList={availableTags}
						onClick={id => {
							setActiveFilters(prev => {
								const tags = prev.tags;
								const findTag = availableTags.find(el => el.id === id);
								const isPresent = tags.includes(findTag.id);
								const updateTags = isPresent
									? tags.filter(item => item !== findTag.id)
									: [...tags, findTag.id];

								return {
									...prev,
									tags: updateTags,
								};
							});
						}}
						selectedValues={activeFilters?.tags}
					/>
					<IconButton
						onClick={() => {
							setIsFetchReport(true);
						}}
						size='small'
					>
						{isFetchReport ? (
							<Preloader color={COLORS.icon} size={24} />
						) : (
							<DownloadLogo stroke={COLORS.icon} />
						)}
					</IconButton>
				</Box>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'row'}
				alignItems={'flex-end'}
				gap={'8px'}
			>
				<TabsFilter
					availableTabs={availableTabs}
					onClick={key => {
						if (
							key === InspectionStatus.Expired ||
							key === InspectionStatus.Required
						) {
							handleTabs('inspectionStatus', key);
						} else if (key === TreatmentStatus.DisposeRecommended) {
							handleTabs('treatmentStatus', key);
						} else {
							returnInitialProps('all');
						}
					}}
				/>
				<FilterContent
					activeFilters={activeFilters}
					availableFilters={availableFilters}
					openFilter={openFilter}
					toggleFilter={toggleFilter}
					setActiveFilters={setActiveFilters}
					setOpenFilter={setOpenFilter}
					handleDayChange={handleDayChange}
					returnInitialProps={returnInitialProps}
					turnOnFilters={turnOnFilters}
					titleForFilter={titleForLamenessFilter}
					isEqualFilter={isEqualFilter}
					isEqualLamenessFilter={isEqualLamenessFilter}
					isEqualTreatmentFilter={isEqualTreatmentFilter}
					isEqualInspectionFilter={isEqualInspectionFilter}
					isEqualLactationFilter={isEqualLactationFilter}
					isEqualCowAgeFilter={isEqualCowAgeFilter}
					isEqualGroupsFilter={isEqualGroupsFilter}
					isEqualFatnessFilter={isEqualFatnessFilter}
					isEqualExaminersFilter={isEqualExaminersFilter}
					isEqualDiagnosisFilter={isEqualDiagnosisFilter}
				/>
			</Box>
			<LamenessTable
				cattlesInfo={cowsList}
				fetchingCowsList={fetchingCowsList}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<Divider />
				<CustomPagination
					onChangeFromServer={setPage}
					count={initialPageSize}
					totalCount={totalCountPages}
					isResetPage={!isEqualFilter}
				/>
			</Box>
		</CardStandart>
	);
};

export default LamenessList;

const initialTabs = [
	{
		key: 'all',
		title: 'Все',
		description: null,
	},
	{
		key: InspectionStatus.Required,
		title: 'Требуется осмотр',
		description: null,
	},
	{
		key: InspectionStatus.Expired,
		title: 'Осмотр просрочен',
		description: null,
	},
	{
		key: TreatmentStatus.DisposeRecommended,
		title: 'Рекомендованы на выбытие',
		description: null,
	},
];
