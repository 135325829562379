import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import { COLORS } from '../styles/colors';
import { TypographyPrimary } from './typography/TypographyPrimary';

const CustomPagination = props => {
	const {
		initialArray,
		setVisibleState,
		count,
		onChangeFromServer,
		isResetPage = false,
		totalCount,
	} = props;
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = count;

	const totalItems = totalCount ? totalCount : initialArray?.length;
	const pageCount = Math.ceil(totalItems / itemsPerPage);
	const handleChange = (event, value) => {
		setCurrentPage(value);
	};

	useEffect(() => {
		if (onChangeFromServer) {
			onChangeFromServer(currentPage);
		}
	}, [currentPage, onChangeFromServer]);
	useEffect(() => {
		if (isResetPage) {
			setCurrentPage(1);
		}
	}, [isResetPage]);

	useEffect(() => {
		if (initialArray) {
			setCurrentPage(1);
		}
	}, [initialArray]);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

	useEffect(() => {
		if (initialArray) {
			const visibleItems = initialArray.slice(startIndex, endIndex);
			setVisibleState(visibleItems);
		}
	}, [initialArray, currentPage, startIndex, endIndex]);

	return (
		<>
			{(totalCount || initialArray?.length) && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box>
						<TypographyPrimary>
							<span style={{ fontWeight: '600' }}>{`${
								startIndex + 1
							} - ${endIndex}`}</span>{' '}
							из{' '}
							<span style={{ fontWeight: '600' }}>
								{totalCount ? totalCount : initialArray?.length}
							</span>
						</TypographyPrimary>
					</Box>
					<Pagination
						count={pageCount}
						page={currentPage}
						onChange={handleChange}
						shape='rounded'
						color='primary'
						size='small'
						boundaryCount={1}
						sx={{
							'& .MuiPaginationItem-root': {
								fontSize: '14px',
							},
							'& .Mui-selected': {
								fontWeight: '600',
								backgroundColor: '#EEE9FA !important',
								color: `${COLORS.main} !important`,
								'&:hover': {
									backgroundColor: '#EEE9FA !important',
								},
							},
						}}
					/>
				</Box>
			)}
		</>
	);
};

export default CustomPagination;
