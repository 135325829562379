import { IFiltersLamenessTable } from 'components/cattles/lamenessPage/shared/models';
import fileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import CattlesApiService from 'services/cattlesApiServices';
import { useAppDispatch } from 'store/hooks';
import { notifyUser } from 'store/notificationsSlice';

interface IExportLamenessReport {
	page: number;
	activeFilter?: any;
	initialPageSize: number;
	fatnessPoint?: number[];
	params?: IFiltersLamenessTable;
}

export const useExportCowsListReport = (props: IExportLamenessReport) => {
	const { params, page, activeFilter, initialPageSize, fatnessPoint } = props;
	const [isFetchReport, setIsFetchReport] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (isFetchReport) {
			CattlesApiService.getExportReport(
				activeFilter === 'all' || !activeFilter ? null : activeFilter,
				fatnessPoint,
				page,
				initialPageSize,
				params
			)
				.then(response => {
					const blob = new Blob([response.data], {
						type: response.headers['content-type'],
					});
					fileSaver.saveAs(blob, 'report');
				})
				.catch(error => {
					dispatch(
						notifyUser({
							title: `Генерация отчёта завершилась с ошибкой`,
							description: error.message,
							isError: true,
						})
					);
				})
				.finally(() => setIsFetchReport(false));
		}
	}, [
		activeFilter,
		dispatch,
		fatnessPoint,
		initialPageSize,
		isFetchReport,
		page,
		params,
	]);
	return { isFetchReport, setIsFetchReport };
};
