import { COLORS } from 'styles/colors';

type PropsType = {
	iconColor?: string;
	width?: string;
	height?: string;
	direction?: 'up' | 'down';
};

const ArrowCupidon = (props: PropsType) => {
	const {
		iconColor = COLORS.success,
		width = '17px',
		height = '17px',
		direction = 'up',
	} = props;
	const rotation = direction === 'up' ? 'rotate(0deg)' : 'rotate(180deg)';

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 17 17'
			fill='none'
			style={{ transform: rotation }}
		>
			<path
				d='M8.41797 3.55078L8.41797 13.5508'
				stroke={iconColor}
				strokeWidth='1.33333'
				strokeLinecap='round'
			/>
			<path
				d='M13.7515 8.2168L8.41813 2.88346L3.0848 8.2168'
				stroke={iconColor}
				strokeWidth='1.33333'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default ArrowCupidon;
