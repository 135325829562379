import { Box, styled } from '@mui/material';


export const Row = styled(Box)<{$width?: string; $justify?: 'space-between' | 'flex-start' | 'flex-end', $gap?: string; $align?: string}>`
width: ${({$width}) => $width ? $width : '100%'};
gap: ${({$gap}) => $gap ? $gap : 'none'};
display: flex;
flex-direction: row;
align-items: ${({$align}) => $align ? $align : 'center'};
justify-content: ${({$justify}) => $justify ? $justify : 'none'};
`