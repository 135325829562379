import { Box } from '@mui/material';
import { ReactComponent as ChartIcon } from 'assets/chart-icon.svg';
import BreadCrumbsGroup from 'components/shared/breadCrumbs/BreadCrumbsGroup';
import { getDatesArray } from 'components/shared/processes';
import useLamenessInfoForChart from 'hook/useLamenessInfoForChart';
import { useState } from 'react';
import { COLORS } from 'styles/colors';
import { CardStandart } from 'ui/CardStandart';
import Preloader from 'ui/Preloader';
import TooltipHover from 'ui/TooltipHover';
import RangeDate from 'ui/datePicker/RangeDate';
import DefaultSelectForm from 'ui/menu/DefaultSelectForm';
import { TypographyHeader } from 'ui/typography/TypographyHeader';
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14';
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16';
import LamenessChart from '../shared/LamenessChart';
import { LamenessTypeChart } from '../shared/models';

const LamenessCardChart = () => {
	const [selectTypeOfChart, setSelectTypeOfChart] = useState<LamenessTypeChart>(
		LamenessTypeChart.LamenessType
	);
	const [activeFilter, setActiveFilter] = useState('all');
	const weekDays = getDatesArray(6);
	const [dateRange, setDateRange] = useState({
		startDate: weekDays.startDate,
		endDate: weekDays.endDate,
	});
	const { dotsInfo, isLoading, lightGroupList, daysBetweenDates } =
		useLamenessInfoForChart(dateRange, activeFilter);

	const handleActiveFilter = name => {
		setActiveFilter(name);
	};

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		});
	};

	const menuDescription = [
		{
			description: 'По количеству хромых',
			value: LamenessTypeChart.LamenessType,
		},
		{
			description: 'По баллам',
			value: LamenessTypeChart.PointType,
		},
	];

	return (
		<CardStandart
			sx={{
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				minHeight: '500px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '8px',
				}}
			>
				<TypographyHeader>График хромоты</TypographyHeader>
				<TooltipHover title={'Изменить вид графика'} placement={'top-end'}>
					<DefaultSelectForm
						textTitle={<ChartIcon stroke={COLORS.secondary} />}
						menuDescription={menuDescription}
						onClick={(value: string) =>
							setSelectTypeOfChart(value as LamenessTypeChart)
						}
						isArrow
						selectedMenu={[selectTypeOfChart]}
					/>
				</TooltipHover>
			</Box>
			<BreadCrumbsGroup
				handleActiveFilter={handleActiveFilter}
				array={lightGroupList}
			/>
			<RangeDate
				onChangeUpdateDate={onChangeCallback}
				weekFilter={true}
				monthFilter={true}
				yearFilter={true}
				currentRange={'Неделя'}
				startDate={dateRange.startDate}
				endDate={dateRange.endDate}
			/>
			{isLoading ? (
				<Preloader mt={6} />
			) : dotsInfo.every(el => el.category.length === 0) ? (
				<Box
					sx={{
						height: '240px',
						flexDirection: 'column',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '12px',
					}}
				>
					<TypographySecondaryBold16>Нет данных</TypographySecondaryBold16>
					<TypographySecondary14>
						Нет данных, соответствующих заданным условиям.
					</TypographySecondary14>
				</Box>
			) : (
				<LamenessChart
					dotsInfo={dotsInfo}
					daysBetweenDates={daysBetweenDates}
					selectTypeOfChart={selectTypeOfChart}
				/>
			)}
		</CardStandart>
	);
};

export default LamenessCardChart;
