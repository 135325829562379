type PropsType = {
	iconColor?: string;
	width?: string;
	height?: string;
};
const HomeLogo = (props: PropsType) => {
	const { iconColor = 'white', width = '34px', height = '34px' } = props;

	return (
		<svg width={width} height={height} viewBox='0 0 16 16' fill='none'>
			<path
				d='M2.66602 7.3908C2.66602 6.99859 2.8387 6.62627 3.13812 6.37293L7.13812 2.98847C7.63524 2.56785 8.36346 2.56785 8.86058 2.98847L12.8606 6.37293C13.16 6.62627 13.3327 6.99859 13.3327 7.3908V12.0005C13.3327 12.7368 12.7357 13.3338 11.9993 13.3338H3.99935C3.26297 13.3338 2.66602 12.7368 2.66602 12.0005V7.3908Z'
				stroke={iconColor}
				strokeWidth='1.33333'
			/>
			<rect
				x='6'
				y='9.33398'
				width='4'
				height='4'
				rx='0.666667'
				stroke='white'
				strokeWidth='1.33333'
			/>
		</svg>
	);
};

export default HomeLogo;
